.sectors-container {
  margin-top: 82px;
  text-align: center;
  width: 2.5%;
  z-index: 2100;
  position: sticky;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.sectors {
  background-color: #303030;
  border-radius: 4px;
  margin-top: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  z-index: 5;
}

.sectors p {
  transform: rotate(-90deg);
  white-space: nowrap;
}

.timeline-container {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
  transition: all 0.25s ease-in-out;

  .timeline-month {
    z-index: 1;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .day-name {
    font-size: 9px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 8px;
    text-align: center;
    background-color: #303030;
    border-radius: 4px;
    padding: 2px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .active-day {
      position: absolute;
      height: 10000px;
      border-style: dashed;
      border-width: 1px;
      top: 60px;
      border-color: #ff6c37;
    }
  }

  .item {
    z-index: 5;
    transition: all 0.25s ease-in-out;
  }

  .item:hover {
    transform: scale(1.1);
  }

  .item .item-dot {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    box-shadow: 2px 2px 2px 2px rbga(255, 255, 255, 0.5);
    font-size: 11px;
    line-height: 1;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-top: -1px;
  }

  .item .item-dot-start {
    left: 0;
    // display: none;
  }

  .item .item-dot-end {
    right: 0;
    // display: none;
  }

  .item .item-center {
    width: 100%;
    line-height: 0;
    margin-top: -1px;
    font-size: 12px;
    height: 20px;
    font-weight: bold;
    text-align: center;
    box-shadow: 2px 1px 3px 0px rgba(0, 0, 0, 1);
  }

  .item .item-center * {
    margin-top: 9px;
    color: #efefef;
  }

  .timeline-header {
    background-color: #000;
  }

  .active-week {
    border-top: 2px solid #ff6c37 !important;
  }
}

.add-item-button {
  transition: all 0.25s ease-in-out;
}

.add-item-button:hover {
  transform: scale(1.1);
}

.light-mode {
  .timeline-container {
    background-color: #fff !important;
  }

  .timeline-week {
    background-color: #ddd !important;
    color: #222 !important;
  }

  .timeline-month {
    background-color: #222 !important;
  }
  .sectors-container {
    .sectors {
      background-color: #fff;
    }
  }

  .timeline-container {
    .day-name {
      background-color: #fff;
      color: #222 !important;
    }
  }

  .top-sticky {
    background-color: #eee !important;
  }
}

.year-switcher polyline {
  stroke: rgb(225, 225, 225) !important;
}
