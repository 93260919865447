.show-menu {
  display: block;
}

.hide-menu {
  display: none;
}

a {
  color: #eee;
  text-decoration: none;
}

a:hover {
  color: #fff;
}

.item-menu {
  margin: 3px 0;
  display: flex;
  border-bottom: 1px solid #333;
  padding: 8px 16px;
}
.item-menu a {
  padding: "8px 16px";
}
