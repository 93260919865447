.left-side-item {
  display: flex;
  align-items: flex-start;
  padding: 20px 8px;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  border: 1px solid grey;
  margin: 10px 10px 10px 0;
  justify-content: center;
  background-color: #343334;
  flex-direction: column;

  &:hover {
    transform: translateY(-4px);
  }

  span {
    white-space: nowrap;
    text-align: left;
    margin-top: -10px;
    width: 100%;
  }

  .pill {
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #00bc8c;
    background-color: #00bc8c;
    border-radius: 50%;
    margin-top: 50px;
    margin: 0 auto;
    font-size: 18px;
  }

  svg {
    margin: 0;
    padding: 0;
  }
}

.active-item {
  background-color: #242424 !important;
  color: #fff;
}

.inactive-item {
  background-color: #343334 !important;
  opacity: 0.5;
}

.item-false {
  border: 2px solid #e85434;
}

.item-success {
  border: 2px solid #00bc8c;
  opacity: 1;
}
