.light-mode {
  background-color: #efefef;
  color: #212121;

  .darkMode-switcher {
    cursor: pointer;
    padding: 4px;
  }

  .top-bar {
    border-bottom: 1px solid #ccc;
  }

  .side-menu {
    border-right: 1px solid #ccc;
  }

  .right-menu {
    border-left: 1px solid #ccc;
    padding: 4px;
  }

  .content {
    flex: 1;
    background-color: #fefefe;
  }
}

body {
  overflow: hidden;
}
