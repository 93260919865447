.dark-mode {
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 8px !important;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #777;
  }

  background-color: #212121;
  color: #efefef;

  .darkMode-switcher {
    cursor: pointer;
    padding: 4px;
  }

  .top-bar {
    border-bottom: 1px solid #333333;
  }

  .side-menu {
    border-right: 1px solid #333333;
  }

  .right-menu {
    border-left: 1px solid #333333;
    padding: 4px;
  }

  .content {
    flex: 1;
    background-color: #212121;
  }
}

.my-modal {
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200000;
  background-color: #303135;
  padding: 16px;
  border-radius: 10px;
  min-width: 75vw;
  min-height: 70vh;
  max-height: 80vh;
  transition: all 0.2s ease-in-out;
  border: 1px solid #2ba7f3;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.5);

  h4 {
    color: #e8eadc;
    line-height: 1;
    margin-top: 5px;
    font-size: 1.55rem;
  }

  svg {
    margin-right: 5px;
  }
  path {
    stroke: #e8eadc;
  }

  hr {
    border-width: 2px;
  }

  .icon-own {
    background-color: #2ba7f3;
    padding: 5px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: 35px;
    height: 35px;

    svg {
      margin: 0;
    }

    &:hover {
      transform: translateY(-4px);
    }
  }

  .modal-close {
    background-color: #db4437;
    padding: 5px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    svg {
      margin: 0;
    }

    &:hover {
      transform: translateY(-4px);
    }
  }
}

.bg-orange {
  background-image: linear-gradient(
    to right,
    rgb(255, 108, 55),
    rgb(211, 74, 25)
  );
  border: 1px solid #ff8c00 !important;
}

.ql-toolbar.ql-snow {
  border: 1px solid #333333;
  border-radius: 8px 8px 0 0;
  background-color: #eee;
}

.quill {
  height: 90%;
}

.ql-container {
  border-radius: 0 0 8px 8px;
  border: 1px solid #333333;
  background-color: #eee !important;
  color: #222;
}
