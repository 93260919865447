// .stats-container tbody {
//   display: block;
//   height: 500px;
//   width: 100%;
//   overflow: auto;
// }

// .stats-container tbody tr {
//   display: table;
//   // // width: 100%;
//   // table-layout: fixed;
// }

// .stats-container table {
//   width: 400px;
// }

.stats-container table {
  margin: 0;
}

.stats-container .right-side-menu {
  transition: all 1s ease-in-out;
}
