.dark-mode {
  .left-menu-item {
    color: #999;
    &:hover {
      background-color: #444;
      color: #eee;
    }

    &.active {
      background-color: #333;
      border-left: 2px solid #ff6c37;
      color: #eee;
      font-weight: bolder;
    }
  }
}

.light-mode {
  color: #777;
  .left-menu-item {
    &:hover {
      background-color: #ddd;
      color: #212121;
    }

    &.active {
      background-color: #ddd;
      border-left: 2px solid #ff6c37;
      color: #212121;
      font-weight: bolder;
    }
  }
}

.left-menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0.375rem;
  padding: 8px 32px;
  margin-bottom: 8px;
  transition: background-color 0.25s ease-in-out;

  svg {
    font-size: 20px;
  }

  .item-name {
    font-size: 11px;
    margin-top: 6px;
  }
}
