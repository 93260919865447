.number-custom-cell {
  .colored-cell {
    transition: all 1s ease-in-out;
    position: absolute;
    width: 0px;
    padding: 0;
    margin: 0;
  }

  .cell-value {
    z-index: 2 !important;
    position: relative;
    background-color: transparent !important;
    padding: 0 !important;
    color: #fff !important;
    text-align: right !important;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }
}
