.right-side-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .item {
    .selectedDate {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .content-right {
    flex: 1;
  }

  .label {
    text-transform: uppercase;
    font-weight: bold;
  }
  .content {
    margin-top: 15px;
  }

  .rdw-editor-main {
    background-color: white;
    color: black;
  }

  .rdw-editor-toolbar {
    margin-bottom: 0;
  }

  .parent-select {
    display: flex;
    flex-direction: row;
    padding: 8px;

    .select-option {
      background-color: #53555b;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 16px;
      border-radius: 8px;
      border-top: 4px solid white;
      border-bottom: 4px solid white;
      margin: 4px;
      text-align: center;
      height: 150px;
      flex: 1;
      max-width: 200px;

      &:hover {
        cursor: pointer;
        background-color: #222;
      }
    }

    .active-option {
      background-color: #222;
    }
  }
}

.my-alert-danger {
  background-color: #e85434;
  color: #f8d7da;
  border-color: #f5c6cb;
  padding: 8px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.my-alert-success {
  background-color: #00bc8c;
  color: #f8d7da;
  border-color: #f5c6cb;
  padding: 8px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-columns {
  display: flex;

  & > * {
    flex: 1;
  }
}
