.suppliers {
  .select-columns {
    display: flex;

    h4 {
      color: #888;
      font-size: 20px;
      text-transform: uppercase;
    }

    & > * {
      flex: 1;
      margin: 8px;
      border: 1px solid #eee;
      border-radius: 8px;
      padding: 8px;
      overflow-y: auto;
      max-height: 500px;
      background-color: #eee;
      color: #666;
    }

    input[type="text"] {
      width: 100%;
      padding: 8px;
      border-radius: 8px;
      border: 1px solid #eee;
      margin-bottom: 8px;
    }

    .item {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      align-items: center;
    }
  }
}
